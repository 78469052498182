import React from 'react';
import './ModalLoader.css';
import { defaultSPALogoData } from '../../helpers';
import { IBLSpinLoader } from '@iblai/ibl-web-react-common';

const ModalLoader = ({ useIBLSpinner = false, displayText = 'Loading...' }) => {
  return (
    <div className="modal-loader-block">
      <div className="w-layout-vflex modal-loader-content">
        <div className="w-layout-hflex modal-loader-icon-box">
          {
            useIBLSpinner ? <IBLSpinLoader size={45} /> :
              <img src={defaultSPALogoData.logo} loading="lazy"
                   alt="" className="modal-loader-icon" />
          }
        </div>
        <h5 className="heading">{displayText}</h5>
      </div>
    </div>
  );
};

export default ModalLoader;