export function areSubdomainsOfSameParentDomain(domain1, domain2) {
  if (domain1 === domain2) {
    return true;
  }
  // Split the domains into their parts
  const parts1 = domain1.split('.').reverse();
  const parts2 = domain2.split('.').reverse();

  // Ensure both domains have at least two parts (e.g., "domain.com")
  if (parts1.length < 2 || parts2.length < 2) {
    return false;
  }

  return (
    parts1.slice(0, 2).reverse().join('.') ===
    parts2.slice(0, 2).reverse().join('.')
  );
}

export function getParentDomain(domain) {
  if (!domain) {
    return '';
  }
  const parts = domain.split('.');
  return parts.length > 1
    ? `.${parts.slice(-1 * (parts.length - 1)).join('.')}`
    : domain;
}
