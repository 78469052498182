import { useContext, useEffect, useState } from 'react';
import { AuthContext } from '../utils/context';

const useNotification = () => {
    const authData = useContext(AuthContext);

    useEffect(() => {
        if(authData?.defaultNotificationMsg){
            notify('Important', authData?.defaultNotificationMsg, 'info');
        }
    }, []);

    const [notifications, setNotifications] = useState({});

    const notify = (headerMsg, displayMsg, type) => {
        setNotifications({...notifications, [Object.keys(notifications).length]: {headerMsg, displayMsg, type}});
    }

    const onClose = (key) => {
        const {[key]: remove, ...newNotifications} = notifications;
        setNotifications(newNotifications);
    }

    return [notifications, notify, onClose];
}

export default useNotification;