import { IBLSpinLoader, api } from '@iblai/ibl-web-react-common';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
const selectDefaultApp = (apps) => {
  if (apps.length === 1) {
    return apps[0];
  }
  const app = apps.find((appObj) => appObj.app.type === 'web');
  if (app) {
    return app;
  } else {
    return apps[0];
  }
};

const Home = () => {
  const navigate = useNavigate();
  useEffect(() => {
    api.ibldmfeatures.getApps({}, (data) => {
        const selectedApp = selectDefaultApp(data.results);
        window.location.href = selectedApp.app.url;
      },
      (error) => {
        console.error(error);
        localStorage.removeItem('redirectTo');
        navigate('/login');
      }
    );
  }, []);
  return (
    <>
      <IBLSpinLoader />
    </>
  );
};

export default Home;
