import React, { useEffect, useRef, useState } from 'react';
import closeImg from '../../assets/images/x.svg';
import iconImg from '../../assets/images/alert-circle.svg';
import './FreeTrial.css';
import { IBLSpinLoader } from '@iblai/ibl-web-react-common';
import { useLocation } from 'react-router-dom';
import ModalLoader from '../ModalLoader/ModalLoader';

const FreeTrial = () => {
  const location = useLocation();
  const FREE_TRIAL_ENABLED =
    process.env.REACT_APP_IBL_ENABLE_FREE_TRIAL === 'true';
  const FREE_TRIAL_QUERY_PARAM =
    process.env.REACT_APP_IBL_FREE_TRIAL_PRODUCT_GET_QUERY_PARAM_NAME;

  const [submissionStart, setSubmissionStart] = useState(false);
  const [productName, setProductName] = useState('');

  const topHeaderRef = useRef(null);
  const tooltipRef = useRef(null);
  const [showModal, setShowModal] = useState(false);

  const freeTrialSubscriptionAPI = async (
    data,
    successCallback,
    errorCallback
  ) => {
    const url = `${process.env.REACT_APP_IBL_DM_URL}/api/service/stripe/checkout/free-trial/`;
    await fetch(url, {
      method: 'POST',
      body: JSON.stringify(data),
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then((resp) => {
        resp
          .json()
          .then((data) => {
            console.log(data);
            if (!data?.redirect_to) {
              throw new Error('Free Trial Subscription URL not available');
            }
            successCallback(data?.redirect_to);
          })
          .catch((err) => {
            errorCallback(err);
          });
      })
      .catch((err) => {
        errorCallback(err);
      });
  };

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const _productNameQueryParam = queryParams.get(FREE_TRIAL_QUERY_PARAM);
    setProductName(_productNameQueryParam);
    if (_productNameQueryParam && FREE_TRIAL_ENABLED) {
      setShowModal(true);
      handleStartTrialBtn(null, _productNameQueryParam);
    }
  }, []);

  const handleCloseBtn = () => {
    topHeaderRef.current.style.display = 'none';
  };

  const handleTooltipShowHide = () => {
    tooltipRef.current.classList.toggle('show');
  };

  const handleStartTrialBtn = (e, fallbackProductName = '') => {
    if (e) {
      e.preventDefault();
    }
    const url = new URL(window.location.href);
    const successUrl = `${url.origin}/create-organization`;
    const data = {
      product: productName || fallbackProductName,
      success_url: successUrl,
      cancel_url: `${url.origin}/login`,
      //callback: "createFreeTrialSubscription",
    };

    setSubmissionStart(true);
    freeTrialSubscriptionAPI(
      data,
      (url) => {
        localStorage.setItem(
          FREE_TRIAL_QUERY_PARAM,
          productName || fallbackProductName
        );
        window.location.href = url;
      },
      (err) => {
        console.log(err);
      }
    );
    /*api.iblwebstripe.postToStripe(data, function (url) {
      localStorage.setItem(FREE_TRIAL_QUERY_PARAM, (productName || fallbackProductName));
      window.location.href = url;
    });*/
  };

  return !(FREE_TRIAL_ENABLED && !!productName) ? (
    <></>
  ) : (
    <>
      {showModal && (
        <ModalLoader
          useIBLSpinner={true}
          displayText={'Redirecting you to Stripe...'}
        />
      )}
      <section
        react-component="topHeader"
        className="site-top-header free-trial-component"
        ref={topHeaderRef}
      >
        <div className="w-layout-hflex site-top-header-container">
          <div
            onMouseEnter={() => handleTooltipShowHide()}
            onMouseLeave={() => handleTooltipShowHide()}
            data-w-id="b317907b-c6e2-d238-a40a-e496d0e299fe"
            className="w-layout-hflex top-header-icon-container"
          >
            <img
              src={iconImg}
              loading="lazy"
              height="20"
              alt=""
              className="top-header-img"
            />
            <div className="tooltip tooltip-trial" ref={tooltipRef}>
              Try first and decide later. No credit card required
            </div>
          </div>
          <div className="top-header-text">Start 14 Days Free Trial</div>
          {submissionStart ? (
            <div className={'loader-container'}>
              <IBLSpinLoader size={17} color={'#FFF'} />
            </div>
          ) : (
            <a
              onClick={(e) => handleStartTrialBtn(e)}
              href="#"
              className="top-header-question-link w-inline-block"
            >
              <div className="top-header-text pill">Start Trial</div>
            </a>
          )}

          <div
            prop-events-value-onclick="handleTopHeaderCloseClick"
            onClick={() => handleCloseBtn()}
            prop-events-names="onClick"
            className="w-layout-hflex top-header-closer-container"
          >
            <img
              src={closeImg}
              loading="lazy"
              alt=""
              className="top-header-closer-icon"
            />
          </div>
        </div>
      </section>
    </>
  );
};

export default FreeTrial;
