import React from 'react';
import { Link } from 'react-router-dom';

const TERMS_OF_USE_URL = process.env.REACT_APP_TERMS_OF_USE_URL;
const PRIVACY_POLICY_URL = process.env.REACT_APP_PRIVACY_POLICY_URL;
const Footer = () => {
  return (
    <div className="w-layout-hflex auth-footer">
      {
        !!TERMS_OF_USE_URL && <Link target={'_blank'} to={TERMS_OF_USE_URL} className="link-block w-inline-block">
          <div className="auth-footer-links">Terms of Use</div>
        </Link>
      }
      {
        (!!TERMS_OF_USE_URL || !!PRIVACY_POLICY_URL) && <div className="div-block-35"></div>
      }
      {
        !!PRIVACY_POLICY_URL && <Link target={'_blank'} to={PRIVACY_POLICY_URL} className="link-block w-inline-block">
          <div className="auth-footer-links">Privacy Policy</div>
        </Link>
      }
    </div>
  );
};

export default Footer;